@import url('https://fonts.googleapis.com/css2?family=Nunito&display=swap');

html {
  font-family: 'Nunito', sans-serif;
  color: #fff;
}
body,
html {
  margin: 0;
  padding: 0;
  height:100%;
}
* {
  box-sizing: border-box;
}
blockquote {
  padding:10px;
  border-left:5px solid #999999;
  background-color: #b9dacb;
  margin:0px;
}
#root {
  min-height: calc(100vh);
  margin:0px;
  padding:0px;
} 

.MainDiv, .container-fluid {
  margin:0px;
  padding:0px;

}
.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: calc(100vh + 2.5rem);
  margin:0px;
  padding:0px;
}
.Dashboard .row {

  margin-bottom: 50px;
  background-color: rgba(#ffff);
}
.Dashboard .row:last-of-type {
  margin-bottom: 120px;
}

.Dashboard h1 {
  font-size: 28px;
  color: #22284e;
}
.Dashboard .showcase-img {
  z-index: 1;
  width: 100%;
  height: 200px;
  border-radius: 15px;
  margin-bottom:10px;
  overflow:hidden;
}
.Dashboard {
  height: 100%;
  
}

.Dashboard .content {
  position: relative;
  width: 100%;
  height: 100%;
  position: relative;
  color: #22284e;
  font-size: 18px;
  z-index: 5;
  padding: 20px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5rem 0.75rem 0rem;
  
}

.Dashboard a {
  text-decoration: none;
  font-size: 20px;
  font-weight: 600;
  color: #2539b9;
  position: absolute;
  bottom: 10px;
  opacity: 1;
}

.Generator select {
  width: 100%;
  height: 100%;
  font-family: Inter, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 1rem 1.125rem;
  border: 0.0625rem solid transparent;
  appearance: none;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0rem 0.5rem 0.75rem 0rem;
  transition: border-color 0.1s ease 0s;
  margin-bottom:20px;
}

.Generator h1 {
  font-size: 3.25rem;
  line-height: 3.75rem;
  font-style: normal;
  font-weight: 400;
  font-size: 2.625rem;
  line-height: 3.125rem;
  letter-spacing: -0.0625rem;
  color: rgb(0, 0, 0);
}
.Generator {
  font-size: 18px;
  color: #22284e;
}

.Generator label {
  font-family: Inter, Helvetica, sans-serif;
  font-style: normal;
  font-size: 1rem;
  line-height: 1.25rem;
  font-weight: 600;
  color: rgb(0, 0, 0);
  display:block;
  line-height: 3.125rem;
}

.Generator input {
  width: 100%;
  height: 100%;
  font-family: Inter, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.25rem;
  padding: 1rem 1.125rem;
  border: 0.0625rem solid transparent;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0rem 0.5rem 0.75rem 0rem;
  appearance: none;
  transition: border-color 0.1s ease 0s;
}
.hidden {
  display:none;
}
.results p:first-child {
  font-weight: bold;
  font-size: 130%;
}
.results p:last-child {
  background-color:#efefef;
  padding:40px;
}
.results {
  min-height:300px;
}

.results .result-div {
  
  position:relative;
  color:#333333;
  display:inline-flex;
  padding: 1rem 1.125rem;
  padding-right:100px;
  min-height:120px;
  border:1px solid #efefef;
  border-radius: 0.45rem;
  box-shadow: rgba(0, 0, 0, 0.15) 0rem 0.2rem 0.5rem 0rem;
  max-width:450px;
  min-width:400px;
  margin-bottom:20px;
  margin-right:20px;
  position:relative;
  font-size:25px;
  word-wrap: break-word;
}

.clip-message{
  position:absolute;
  width:100%;
  height:100%;
  border:1px solid #000000;
  border-radius: 0.45rem;
  top:0;
  left:0;
  visibility: hidden;
  display:flex;
  visibility:hidden;
  justify-content: center;
  align-items: center;
  border: 3px solid green;
  background-color: #fff;
  z-index:98;
}
.checkmark {
  width:30px;
  height:30px;
  z-index:99;
  margin-left:20px;
  background-color: #fff;
  background-image: url('../public/assets/img/checkmark.png');
}
.bg-image {
  position:absolute;
  right:20px;
  top:40px;
  background-image: url('../public/assets/img/copyClip.png');
  background-size:contain;
  background-repeat: no-repeat;
  opacity:.2;
  width:40px;
  height:40px;
  border:none;
}

.button-div {
  position:relative;
}
.info{
  visibility:hidden;
}
#footer {
  position: absolute;
  bottom:- 2.5em;
  left: 0;
  width: 100%;
}
#footer .footer {

  height:2.5rem
}
.row {
   --bs-gutter-x: 0rem;
}
.login-section {
  width: 100%;
  max-width: 420px;
  min-height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  background-color: rgba(172, 169, 197, 0.4);
  margin-top: 70px;
  border-radius: 15px;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 0.5rem 0.75rem 0rem;
}

.clear-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: 70px;
  align-items: center;
}

.wide-section {
  display: flex;
  flex-direction: column;
  margin:0px;
  width:90%;
}

.login-section form {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  flex-grow: 1;
  padding-bottom: 1rem;
  margin: 0 auto;
}

.login-section input[type='text'],
.login-section input[type='password'],
.login-section button,
.login-section textarea {
  font-family: 'Nunito', sans-serif;
  font-size: 22px;
  padding: 0.25rem;
  border-radius: 0.5rem;
  border: 1px solid #adaeb1;
}

.login-section input[type='text']:focus {
  border: 1px solid #adaeb1;
}

.login-section h1 {
  color: #22284e;
}

#footer a {
  padding: 0.5rem;
  color: #efefef;
  text-decoration: none;
  font-size: 20px;
}

.navbar-brand {
  font-size: 30px !important;
  float: left;
  margin-top: 10px;
  font-weight: 600 !important;
}
.navbar-brand a {
  color: #2b29290a;
}

.login-button {
  float: right;
  margin: 10px;
  color: #efefef;
}
.reg-button {
  float: right;
  margin: 10px;
  color: #efefef;
}

.instructions {
  font-size: 0.75rem;
  border-radius: 0.5rem;
  background: #000;
  color: #fff;
  padding: 0.25rem;
  position: relative;
  bottom: -10px;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}

.hide {
  display: none;
}

.valid {
  color: limegreen;
  margin-left: 0.25rem;
}

.invalid {
  color: red;
  margin-left: 0.25rem;
}

.errmsg {
  background-color: lightpink;
  color: firebrick;
  font-weight: bold;
  padding: 1rem;
  margin-bottom: 0.5rem;
  margin-top: 1rem;
  border-radius: 10px;
}

.line {
  display: inline-block;
}

.flexGrow {
  flex-grow: 1;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.persistCheck {
  font-size: 1rem;
  margin-top: 15px;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.persistCheck label {
  margin: 0;
}

[type='checkbox'] {
  height: 20px;
  width: 20px;
  margin: 0 5px 2px 2px;
}

.admin-table .btn,.modal-footer .btn {
  margin:0 5px;
  
}
.admin-table .controls {
  text-align: center;
  justify-content: center;
  width:200px;
}

.admin-table .td {
  width:200px;

}
.admin-table .controls button {
  margin-bottom:7px;
  display:inline-block;
 }

.live-preview-btn{
  width:125px;
}

.admin-table .flex {
  min-width:300px;
  overflow-y: auto;
  max-height:100px;
}

.modal-body{
  overflow-y: auto;
  max-height:600px;
}

.break-me {
  word-wrap: break-word;
  overflow-wrap: break-word;
  display:block;
}

fieldset {
  padding:10px;
  border-width: 1px;
    border-style: groove;
    border-color: rgb(192, 192, 192);
    border-image: initial;
    border-radius: 15px;

}
.prompt-response{
  white-space: pre-line;
}
fieldset.prompt-preview, .prompt-response {
  margin-top:40px;
  position:relative;
  padding:20px;
  
}
fieldset.prompt-preview legend, .prompt-response legend {
  position:absolute;
  top:-25px;
  left:10px;
  width: fit-content;
  background-color: #fff;
  padding:5px;
}
.active-field {
  background-color: #b9dacb !important;
  border:1px solid #285a42 !important;
}

.delete-input {

  float:right;
}

.no-inputs {
  justify-content: center;
  align-items: center;
  text-align: center;
  height:250px;
  padding-top:80px;
  background-color: #b9dacb;
  border:1px solid #285a42;
  border-radius: 15px;
}
.iframe-preview {
  background:#c4c3c3;
  margin-bottom:20px;
  padding:10px;
  font-weight: bold;
}
.float-right {
  margin-left:25px;
  margin-bottom:10px;
  
}
.embed-window {
  color:#000000;
  display: flex;
  flex-direction: column;
  /*white-space: pre-line;*/
  padding:50px;
}
.form-label {
  font-weight: bold;
}
.embed-frame {
  position: fixed;
    top: 80px;
    left: 40px;
    bottom: 150px;
    min-width: 200px;
    left: 0px;
    right: 0px;
    background-color:white;
    border-bottom:1px solid  rgb(90, 128, 184);
}
.embed-frame iframe {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  margin-right:50px;
}
.clearfix{clear:both;}

.hdn-btn{
  position: fixed;
  width:100%;
  display:block;
  left: 0px;
  bottom: 0px;
  height:150px;
}
.hdn-btn .col {
  text-align:end;
  justify-content:flex-end;
  width:100%;
  display:block;
  padding-right:80px;
  padding-top:10px;
 }

.landing-bg {
  min-height: calc(100vh - 82px);
  width:100%;
  display:block;
}

.toolkits-row span {
  display:inline-block !important;
  vertical-align:top;
}
.form-switch {
  width:50px;
  display:inline-block !important;

}

.form-switch .form-check-input {
  padding-bottom:20px;
  display:block !important;
  width:45px;
}

.youtube-iframe, .widget-image {
  
  max-height:300px;
  max-width:500px;
}



.editor-class {
  background-color:#efefef;
  padding: 1rem;
  border: 1px solid #ccc;
}
.toolbar-class {
  border: 1px solid #ccc;
}


.rdw-embedded-modal {
  min-height:220px !important;
  width:250px !important;
}

.rdw-link-modal {
  min-height:260px !important;
  width:250px !important;
}

.rdw-dropdown-selectedtext {
  text-decoration: none !important;
  color: var(--bs-modal-color) !important;
}

.sidebar {
  min-height: 100vh !important;
  height:100%;
  z-index: 100;
  padding: 20px 0 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
  min-width:200px;
}

.sidebar .nav-item  {
  padding: 0.7rem 1.25rem;
  border-bottom:1px solid #444444;
}
.sidebar .nav-item:hover  {
  padding: 0.7rem 1.25rem;
  background-color: #666666;
  border-bottom:1px solid #666666;
}

.sidebar .nav-item a {
  font-size: 1.3rem;
  color: #efefef;
  padding: 0.5rem 1.25rem;
  text-decoration:none;
}

#sidebar-wrapper {

  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;

}
#sidebar-wrapper{
  min-height: 200% !important;
  margin-left: -1rem;
  -webkit-transition: margin .25s ease-out;
  -moz-transition: margin .25s ease-out;
  -o-transition: margin .25s ease-out;
  transition: margin .25s ease-out;
  min-width:230px;
}
#sidebar-wrapper .sidebar-heading {
  padding: 0.875rem 1.25rem;
  font-size: 1.2rem;
}

#page-content-wrapper {
  min-width: 0;
  padding:0 20px;
  margin-bottom:0px;
  background-image: var(--body-bg);
}
